// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "q_q0 d_gv d_cs";
export var heroHeaderCenter = "q_gw d_gw d_cs d_dw";
export var heroHeaderRight = "q_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "q_q1 d_gr d_cw";
export var heroParagraphCenter = "q_gs d_gs d_cw d_dw";
export var heroParagraphRight = "q_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "q_q2 d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "q_q3 d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "q_q4 d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "q_q5 d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "q_q6 d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "q_q7 s_q7";
export var heroExceptionNormal = "q_q8 s_q8";
export var heroExceptionLarge = "q_q9 s_q9";
export var Title1Small = "q_rb s_rb s_rk s_rl";
export var Title1Normal = "q_rc s_rc s_rk s_rm";
export var Title1Large = "q_rd s_rd s_rk s_rn";
export var BodySmall = "q_rf s_rf s_rk s_rD";
export var BodyNormal = "q_rg s_rg s_rk s_rF";
export var BodyLarge = "q_rh s_rh s_rk s_rG";