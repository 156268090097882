// extracted by mini-css-extract-plugin
export var lbContainerOuter = "z_t3";
export var lbContainerInner = "z_t4";
export var movingForwards = "z_t5";
export var movingForwardsOther = "z_t6";
export var movingBackwards = "z_t7";
export var movingBackwardsOther = "z_t8";
export var lbImage = "z_t9";
export var lbOtherImage = "z_vb";
export var prevButton = "z_vc";
export var nextButton = "z_vd";
export var closing = "z_vf";
export var appear = "z_vg";